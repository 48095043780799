import type { NextPage } from 'next'
import Head from 'next/head'
import { useRouter } from 'next/router'

import { LoginLayout } from '../layouts/login'
import rajecLogo from '../images/rajec-logo.svg'
import leadImage from '../images/sports/RAJEC_sport-BEH_bile.png'
import kytkyImage from '../images/decoration/2_kytky.svg'
import motylImage from '../images/decoration/Motyl_2.svg'
import vetvickyImage from '../images/decoration/2_vetvicky.svg'
// import stravaLogo from '../images/strava-logo.svg'
import { api } from '../data/api'
import { useSession } from 'next-auth/react'

const LoginPage: NextPage = () => {
  const router = useRouter()
  const session = useSession()

  const handleLoginWithStrava: any = () => {
    api('/strava/authorize')
      .then((res) => res.json())
      .then((json) => {
        router.push(json.url)
      })
  }

  if (session.status === 'authenticated') {
    router.replace('/')
    return null
  }

  return (
    <LoginLayout>
      <Head>
        <title>Přihlásit se</title>
        <meta
          name="description"
          content="Přihlášení do aplikace Zrozená k pohybu"
        />
      </Head>

      <div className="w-full h-screen flex flex-col justify-between">
        <img src={rajecLogo.src} alt="Rajec logo" className="fixed xs:top-8 sm:top-16 right-8 md:right-16 w-8 sm:w-16 sm:w-24 md:w-40" />

        <div className='flex justify-between px-40 pt-0 md:pt-12'>
        {/* <div> */}
          <div className='relative flex align-bottom'>
            <img src={vetvickyImage.src} alt="" className='absolute opacity-50 w-full xl:w-64 pt-8 -left-32 md:-left-64 xl:-left-48' />

            <div className='-rotate-8 pt-20 text-5xl md:text-9xl mx-[-4rem] font-display max-w-fit md:max-w-md xl:max-w-none'><span className='text-secondary'>ZROZENA</span> K&nbsp;POHYBU</div>

            <img src={motylImage.src} alt="" className='absolute opacity-50 w-60 -right-32 sm:-right-8 xl:-right-32 top-12 xl:top-4' />
          </div>
        </div>

        <div className='container mx-auto flex flex-col justify-center items-center gap-8 pt-8 md:pt-32'>
          <img src={leadImage.src} alt="Piktogram běžce" className='max-w-48 md:max-w-96' />

          <button className='px-8 md:px-16 py-3 md:py-6 mx-auto rounded rounded-full bg-white font-bold tracking-wider text-blu text-xl' type="button" onClick={handleLoginWithStrava}>
              PŘIHLÁSIT SE<br />
              PŘES{' '}<span className='text-[#ec571a]'>STRAVA</span>
              {/* <img src={stravaLogo.src} className='inline' alt="" width={64} height={13} /> */}
          </button>
        </div>

        <div className='flex justify-between items-center pb-4 px-16'>
          <div className='w-1/2'>
              &copy; 2024 KOFOLA A.S.
          </div>
          <div className='w-1/2 flex justify-end'>
            <img src={kytkyImage.src} alt="" width={80} />
          </div>
        </div>
      </div>
    </LoginLayout>
  )
}

export default LoginPage
