import { useSession } from "next-auth/react";
import Head from "next/head"
import { useRouter } from "next/router";

import { Loading } from "../components/Loading";

interface LayoutProps {
  children: React.ReactNode;
}

export const LoginLayout = ({ children }: LayoutProps) => {
  const session = useSession()

  if (session.status === 'loading') {
    return <Loading />
  }

  return (
    <>
      <Head>
        <link rel="icon" href="/icon.png" />
      </Head>

      {children}
    </>
  )
}